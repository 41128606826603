.error-template {
	padding: 40px 15px;
	text-align: center;

	h3 {
		text-align: left;
	}

	ul {
		margin: 10px 0;
		padding: 0;
		text-align: left;
		list-style: none;

		li {
			margin-bottom: 10px;
		}
	}
}

.error-actions {
	margin-top: 15px;
	margin-bottom: 15px;
}

.error-actions .btn {
	margin-right: 10px;
}
